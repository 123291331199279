import React, { useState, useEffect, useRef } from 'react';
import { graphql } from "gatsby"

import Layout from "../components/layout_landing"
import SEO from "../components/seo"
import ContentAndPicture from "../components/content-and-picture"
import globals from "../components/globals"

import { HeroStatic } from "../components/hero"
import ValueBox from "../components/valuebox_new"
import Schedule from "../components/schedule"
import IIIStep from "../components/3step"
import Testimonials from "../components/testimonials"
import { ContactFormTrial } from "../components/contactform"
import SideBlocks from "../components/side-blocks"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'


import { Container, H1, P } from "../components/styledcomponents"
import Button from "../components/button"

const InnerDiv = styled.div`
  width: 50%;
  margin: 2em;
  @media (max-width: ${globals.media.mobile}) {
    width: 80%;
  }
`

const OuterDiv = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  @media (max-width: ${globals.media.mobile}) { 
    flex-direction: column;
  },

`

const ScalableDiv = (props) => {
  const ref = useRef(null);
  useEffect(() => {
    console.log('height', ref.current ? ref.current.offsetHeight : 0);
    console.log('height', ref);
  }, [ref.current]);
  return <InnerDiv ref={ref} >{props.children}</InnerDiv>;
};

const Image = styled(GatsbyImage)`
height: 100 %;
width: 100 %;
`

const BJJ = ({ location, data }) => {
  return (
    <Layout pathname={location.pathname}>
      <SEO
        title="Kleine Ninjas - Team Laurien & Alex - kostenlose Probestunde vereinbaren"
        description="Vereinbare jetzt eine kostenlose Probestunde für die kleinen Ninjas, Kampfsport für Kinder von 3-5 Jahren, in unserer Kampfsportschule in München!"
      />
      <H1 noLine center>Kleine Ninjas, Große Erfahrungen <br /> Bewegung, Spaß und Fairplay für 3-5 Jährige!</H1>
      <OuterDiv>
        <ScalableDiv>
          <Image
            image={data.hero1.childImageSharp.gatsbyImageData}
            alt="Unsere 3-5 Jährigen Ninjas in München" />
        </ScalableDiv>
        <ScalableDiv>
          <ContactFormTrial page_source="ninja-Probetraining" headline="Starte dein Ninja-Abenteuer mit einer kostenlosen Probestunde!" />
        </ScalableDiv>
      </OuterDiv>
      <ContentAndPicture
        h2="Kampfsport für 3-5 jährige: Unsere Kleinen Ninjas"
        description={<>
          <P>
            Unsere Kleinen Ninjas werden von erfahrenen Trainern betreut, die darauf achten, dass die Kinder
            spielerisch und mit viel Spaß lernen und sich dabei sicher und wohl fühlen. Die Entwicklung der
            motorischen Fähigkeiten steht dabei im Vordergrund, da dies die Grundlage für eine erfolgreiche
            Teilnahme am Kampfsport und anderen Sportarten ist. Doch nicht nur körperlich, auch geistig werden
            unsere Ninjas gestärkt und gefördert. Durch das Training erlangen sie mehr Selbstbewusstsein und
            Selbstbehauptung, was ihnen auch im späteren Leben zugutekommen wird.
          </P>
          <P>
            Wir möchten, dass jedes Kind die Möglichkeit hat, Kampfsport auszuprobieren, daher bieten wir eine kostenlose Probestunde an.
            So können die Eltern und Kinder unser Angebot kennenlernen und entscheiden ob sie zu uns passen.
            Wir freuen uns darauf, die Kleinen Ninjas auf ihrem Weg zu begleiten!
          </P>
          <Button
            to={"#contact"}
          >        Zur kostenlosen Probestunde
          </Button>
        </>
        }
        imgLeft={data.hiptoss}
      />

      <ContentAndPicture
        h2="Wie läuft ein Ninja Probetraining ab?"
        list={[
          "Zu deinem ersten Training komme bitte 15 Minuten früher, damit wir dein Kind, dich und euere Ziele besser kennenlernen und dir somit die beste Erfahrung anbieten können.",
          "Für die Probestunde braucht dein Kind nur bequeme Sportklamotten und etwas zu trinken. Wir trainieren Barfuß",
          "Eltern sollten bei der Probestunde anwesend sein, damit sie sehen können, was im Training passiert.",
          "Nach der Einheit nehmen wir uns Zeit für euch um zu besprechen, wie es weiter geht.",
        ]}
        description2={<>
          <Button to={"#contact"}>
            Zur kostenlosen Probestunde
          </Button>
        </>}
        ordered
        imgRight={data.heelhook}
      />

      <Testimonials
        title="Das sagen unsere Mitglieder:"
        values={[
          {
            id: 4,
            review:
              <>Mein Sohn besucht zweimal Wöchentlich das BJJ Training von Laurien und Alex. Beides hochkompetente, emphatische und professionelle Trainer. Er lernt stets neues und fühlt sich sehr wohl. <br />

                Stolz macht es mich, dass er mit 6 Jahren nun in eine Konfliktsituation gekommen ist, aus der er sich so "professionell" entzogen hat, dass sogar die Erzieher gestaunt haben und ihn gefragt haben, wo er dies gelernt hat. Beim BJJ war seine Antwort.<br />
                Wenn Training Früchte trägt ist dies der Lohn für harte Arbeit.

                Wir bedanken uns als Eltern bei Laurien und Alex für das tolle Training und den Weg den sie für unseren Sohn ebnen.</>,
            reviewer: "Alex U.",
            img:
              "https://cdn.shortpixel.ai/spai/q_glossy+ret_img+to_webp/https://lh4.ggpht.com/-tjAsb2ibil4/AAAAAAAAAAI/AAAAAAAAAAA/RIE7jEoWFnU/s128-c0x00000000-cc-rp-mo-ba2/photo.jpg",
          },
          {
            id: 1,
            review:
              <>"Children are great imitators. Give them something great to immitate." <br /> One of the quotes that hang on the walls of TLA.Laurien and Alex are great teachers of the art of Brazilian jiu- jitsu and Yoga.They have the right balance of teaching, fun and community rolled into one.Both kids and adults will find a great place to learn and grow here.</>,
            reviewer: "Ranjit V.",
            img:
              "https://cdn.shortpixel.ai/spai/q_glossy+ret_img+to_webp/https://lh4.ggpht.com/-tjAsb2ibil4/AAAAAAAAAAI/AAAAAAAAAAA/RIE7jEoWFnU/s128-c0x00000000-cc-rp-mo-ba2/photo.jpg",
          },
          {
            id: 2,
            reviewer: "Sebastian R.",
            img:
              "https://lh3.ggpht.com/-EF1fjB92uWs/AAAAAAAAAAI/AAAAAAAAAAA/6Yz6efcUG2g/s128-c0x00000000-cc-rp-mo/photo.jpg",
            review:
              "Kleines, sauberes Gym mit familiärer Atmosphäre. Laurien und Alex machen ihren Job sauber und mit Herz. Sie gehen toll mit Kindern um, haben ein super Verhältnis zu den Jugendlichen und jeder wird als Freund empfangen und nicht als zahlender Kunde gesehen.",
          },
        ]}
      />
      <Button
        to={"#contact"}
      >        Zut kostenlosen Probestunde
      </Button>
      {/* image={data.hero1.childImageSharp.gatsbyImageData}
        alt="Unsere 3-5 Jährigen Ninjas in München" */}

      {/* <div>
        <H1 noLine center>Jetzt ein kostenloses Ninja Probetraining vereinbaren:</H1>
        <ContactFormTrial page_source="ninja-Probetraining" />
      </div>
      <ContentAndPicture
        h2="Kampfsport für 3-5 jährige: Unsere Kleinen Ninjas"
        description={<>
          <P>
            Unsere Kleinen Ninjas werden von erfahrenen Trainern betreut, die darauf achten, dass die Kinder
            spielerisch und mit viel Spaß lernen und sich dabei sicher und wohl fühlen. Die Entwicklung der
            motorischen Fähigkeiten steht dabei im Vordergrund, da dies die Grundlage für eine erfolgreiche
            Teilnahme am Kampfsport und anderen Sportarten ist. Doch nicht nur körperlich, auch geistig werden
            unsere Ninjas gestärkt und gefördert. Durch das Training erlangen sie mehr Selbstbewusstsein und
            Selbstbehauptung, was ihnen auch im späteren Leben zugutekommen wird.
          </P>
          <P>
            Wir möchten, dass jedes Kind die Möglichkeit hat, Kampfsport auszuprobieren, daher bieten wir eine kostenlose Probestunde an.
            So können die Eltern und Kinder unser Angebot kennenlernen und entscheiden ob sie zu uns passen.
            Wir freuen uns darauf, die Kleinen Ninjas auf ihrem Weg zu begleiten!
          </P>
          <Button
            to={"#contact"}
          >        Kostenlose Probestunde sichern
          </Button>
        </>
        }
        imgLeft={data.hiptoss}
      />

      < SideBlocks >
        Unser Ziel ist, Menschen dabei zu helfen, erfolgreich zu sein, und nicht Gründe zu finden, warum sie es nicht können!
      </SideBlocks>

      <ContentAndPicture
        h2="Wie läuft ein Ninja Probetraining ab?"
        list={[
          "Zu deinem ersten Training komme bitte 15 Minuten früher, damit wir dein Kind, dich und euere Ziele besser kennenlernen und dir somit die beste Erfahrung anbieten können.",
          "Für die Probestunde braucht dein Kind nur bequeme Sportklamotten und etwas zu trinken. Wir trainieren Barfuß",
          "Eltern sollten bei der Probestunde anwesend sein, damit sie sehen können, was im Training passiert.",
          "Nach der Einheit nehmen wir uns Zeit für euch um zu besprechen, wie es weiter geht.",
        ]}
        description2={<>
          <Button to={"#contact"}>
            Zur kostenlosen Probestunde
          </Button>
        </>}
        ordered
        imgRight={data.heelhook}
      />

      <Container backgroundColor="transparent" padding="1em 0" />
      <Testimonials
        title="Das sagen unsere Mitglieder:"
        values={[
          {
            id: 4,
            review:
              <>Mein Sohn besucht zweimal Wöchentlich das BJJ Training von Laurien und Alex. Beides hochkompetente, emphatische und professionelle Trainer. Er lernt stets neues und fühlt sich sehr wohl. <br />

                Stolz macht es mich, dass er mit 6 Jahren nun in eine Konfliktsituation gekommen ist, aus der er sich so "professionell" entzogen hat, dass sogar die Erzieher gestaunt haben und ihn gefragt haben, wo er dies gelernt hat. Beim BJJ war seine Antwort.<br />
                Wenn Training Früchte trägt ist dies der Lohn für harte Arbeit.

                Wir bedanken uns als Eltern bei Laurien und Alex für das tolle Training und den Weg den sie für unseren Sohn ebnen.</>,
            reviewer: "Alex U.",
            img:
              "https://cdn.shortpixel.ai/spai/q_glossy+ret_img+to_webp/https://lh4.ggpht.com/-tjAsb2ibil4/AAAAAAAAAAI/AAAAAAAAAAA/RIE7jEoWFnU/s128-c0x00000000-cc-rp-mo-ba2/photo.jpg",
          },
          {
            id: 1,
            review:
              <>"Children are great imitators. Give them something great to immitate." <br /> One of the quotes that hang on the walls of TLA.Laurien and Alex are great teachers of the art of Brazilian jiu- jitsu and Yoga.They have the right balance of teaching, fun and community rolled into one.Both kids and adults will find a great place to learn and grow here.</>,
            reviewer: "Ranjit V.",
            img:
              "https://cdn.shortpixel.ai/spai/q_glossy+ret_img+to_webp/https://lh4.ggpht.com/-tjAsb2ibil4/AAAAAAAAAAI/AAAAAAAAAAA/RIE7jEoWFnU/s128-c0x00000000-cc-rp-mo-ba2/photo.jpg",
          },
          {
            id: 2,
            reviewer: "Sebastian R.",
            img:
              "https://lh3.ggpht.com/-EF1fjB92uWs/AAAAAAAAAAI/AAAAAAAAAAA/6Yz6efcUG2g/s128-c0x00000000-cc-rp-mo/photo.jpg",
            review:
              "Kleines, sauberes Gym mit familiärer Atmosphäre. Laurien und Alex machen ihren Job sauber und mit Herz. Sie gehen toll mit Kindern um, haben ein super Verhältnis zu den Jugendlichen und jeder wird als Freund empfangen und nicht als zahlender Kunde gesehen.",
          },
        ]}
      />
      <Button
        to={"#contact"}
      >        Kostenlose Probestunde sichern
      </Button>
      <IIIStep
        title="Jede Änderung beginnt mit einem Schritt!"
        description="Egal, ob dein Kind selbstvertrauen aufbauen soll, sich auspowern will oder du es körperlich und mental förden möchtest, Team Laurien & Alex hilft euch dabei, Dein Ziel zu erreichen."
        pathname={location.pathname}
        link="#contact"
        values={[
          {
            id: 1,
            img: data.step1,
            value: "Schritt 1 ",
            statement: "Fordere mehr Informationen zum Ninja-Programm mit unserem Kontaktformular an",
          },
          {
            id: 2,
            img: data.step2,
            value: "Schritt 2",
            statement:
              "Wir melden uns bei dir und machen einen unverbindlichen und kostenlosen Termin für eine Probestunde mit dir aus.",
          },
          {
            id: 3,
            img: data.step3,
            value: "Schritt 3",
            statement:
              "Lerne uns und unsere Schule bei deinem Probetraining kennen. Wir freuen uns auf dich!",
          },
        ]}
      /> */}
    </Layout >
  )
}
export const query = graphql`{
  hero1: file(relativePath: { eq: "NinjaTrial/Ninja_swinging.png" }) {
          childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  hiptoss: file(relativePath: { eq: "NinjaTrial/Kleine-Ninjas-play.jpg" }) {
          childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  heelhook: file(relativePath: { eq: "NinjaTrial/Kleine-Ninjas-koordination.jpg" }) {
          childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  triangle: file(relativePath: { eq: "BJJ/Spass_am_BJJ_Training.JPG" }) {
          childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  kids: file(relativePath: { eq: "BJJ/BJJ_Kids-knee-slide-pass.JPG" }) {
          childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust1: file(
    relativePath: { eq: "Kampfsportschule-des-Jahres-Team-Laurien-Alex.jpg" }
  ) {
          childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust2: file(relativePath: { eq: "IBJJF-Zerifikat-TLA-BJJ.JPG" }) {
          childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust3: file(relativePath: { eq: "Deutsche-Meister-BJJ-IBJJF-Kickboxen.jpg" }) {
          childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust5: file(
    relativePath: { eq: "Yogalehrerzertifikat-in-indien-von-Lalit-Kumar-Himalaya-Yoga-Valley.jpg" }
  ) {
          childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust4: file(relativePath: { eq: "Reviews-Team-Laurien-Alex.JPG" }) {
          childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust6: file(
    relativePath: { eq: "Laurien-and-alex-with-Cadu-Francis-purple-belt-graduation.jpg" }
  ) {
          childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  step1: file(
    relativePath: { eq: "Persoenliche-Unterstuetzung-beim-kostenlosen-Probetraining-TLA-Muenchen.JPG" }
  ) {
          childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  step2: file(relativePath: { eq: "Spass-beim-Training-im-Team-Muenchen.JPG" }) {
          childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  step3: file(
    relativePath: { eq: "Fitness-verbessern-team-laurien-alex-chaturanga-liegestuetz.jpg" }
  ) {
          childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  magnet: file(relativePath: { eq: "Cover_Bist_du_gestresst_atemuebungen.jpg" }) {
          childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
}
`
export default BJJ
